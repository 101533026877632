.col-name {
    min-width: 200px;
    max-width: 200px;
}
.col-services {
    min-width: 200px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.col-services .specialists-services-tooltip {
    max-width: 320px !important;
    white-space: initial !important;
}
.col-phone {
    min-width: 150px;
    max-width: 150px;
}
.col-message-box {
    min-width: 200px;
    max-width: 200px;
}
.col-date {
    min-width: 170px;
    max-width: 170px;
}