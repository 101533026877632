[type="file"] {
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: relative;
    left: 999999999px;
    white-space: nowrap;
    width: 1px;
}

[type="file"] + label {
    background: #4e73df;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block;
    outline: none;
    padding: 7px 50px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s;
}


[type="file"] + label:hover {
    background: #2e59d9;
}

.uploading-img {
    margin: 20px auto;
    display: block;
}