.message-box {

}
.message-box textarea {
    width: 100%;
    border: 1px solid #e3e6f0;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.message-box textarea:focus {
    outline: none;
}