.multi-select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.multi-select-container .multi-select {
    max-width: 48%;
    min-width: 48%;
}
.multi-select-container .multi-select-social-filter {
    max-width: 45%;
    min-width: 45%;
    padding: 0;
}
.multi-select-container .multi-select-social-filter .socialRegistrationCheckbox {
    display: flex;
    align-items: center;
}
.multi-select-container .multi-select-social-filter .socialRegistrationCheckbox label {
    margin: 0;
}
.multi-select-container .multi-select-social-filter .socialRegistrationCheckbox label:before,
.multi-select-container .multi-select-social-filter .socialRegistrationCheckbox label:after {
    top: -2px;
}

@media (max-width: 769px) {
    .multi-select-container .multi-select {
        max-width: 68%;
        min-width: 68%;
    }
    .multi-select-container .multi-select-social-filter {
        max-width: 28%;
        min-width: 28%;
    }
}