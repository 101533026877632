.banners-list {
    height: calc(100vh - 98px);
}
.banners-list .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.banners-list .card-body {
    height: 79vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.banner-item-container {
    border-radius: 7px;
    min-height: 435px;
}
.banner-item-container .banner-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}
.banner-itm-image-container {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
}
.banner-item-container .banner-edit-icon {
    position: absolute;
    bottom: 2px;
    right: 25px;
    opacity: 0.8;
}